



























































































































































































































@import '~@/assets/styles/components/modal-cadastro';
.datas-input{
  margin-top: 28px
}
